import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// componentes
import ReadQrcode from "./readQrcode";

export default function Qrcode() {
  // parametros
  const [searchParams] = useSearchParams();
  // usuario
  const [user, setUser] = useState(null);
  // carregamento
  const [loading, setLoading] = useState(true);
  // cores
  const [colorBg, setColorBg] = useState("#1b63f9");
  const [colorBtn, setColorBtn] = useState("#0f20db");

  useEffect(() => {
    if (window.location.search !== "") {
      const idPart = searchParams.get("idPart");
      const idEvent = searchParams.get("idEvent");
      const bg = searchParams.get("colorBg");
      const btn = searchParams.get("colorBtn");

      setColorBg(bg ? `#${bg}` : "#1b63f9");
      setColorBtn(btn ? `#${btn}` : "#0f20db");

      setUser({
        idPart,
        idEvent,
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [searchParams]);

  return (
    <div className="div-general-qrcode" style={{ backgroundColor: colorBg }}>
      {loading ? (
        <a className="a-loading" />
      ) : (
        <ReadQrcode colorBtn={colorBtn} />
      )}
    </div>
  );
}
