import React, { useState } from "react";
import "./styled.css";
// qrcode
import Qreader from "react-qr-reader";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// img
import Logo from "../../assets/image/icon.png";
// componentes
import ListQrcode from "./listQrcode";

export default function ReadQrcode(props) {
  const { colorBtn } = props;
  // leitor
  const [scan, setScan] = useState(false);
  // alertas
  const [qrcodeRead, setQrcodeRead] = useState(false);
  // modal
  const [listQrcode, setListQrcode] = useState(false);

  function readScan(e) {
    setQrcodeRead(true);
  }

  function changeScan() {
    // transform: rotateY(90deg);
    const div = document.getElementById("card-id");

    div.style.transform = "rotateY(90deg)";

    setTimeout(() => {
      setScan(!scan);
      div.style.transform = "rotateY(0)";
    }, 400);
  }

  return (
    <>
      {qrcodeRead && (
        <SweetAlert
          success
          title="Pontos atribuidos"
          onConfirm={() => {
            setQrcodeRead(false);
            setScan(false);
          }}
        >
          Pontos atribuídos ao participante!
        </SweetAlert>
      )}

      {listQrcode && (
        <ListQrcode isOpen={listQrcode} toggle={() => setListQrcode(false)} />
      )}

      <div className="card-general-qrcode" id="card-id">
        {scan ? (
          <Qreader
            onScan={(e) => {
              if (e && scan) {
                readScan(e);
              }
            }}
            className="qrcode-scan"
          />
        ) : (
          <img
            src={Logo}
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: 40,
            }}
          />
        )}
      </div>

      {scan ? (
        <a className="action-reader close-reader" onClick={() => changeScan()}>
          Fechar Scan
        </a>
      ) : (
        <>
          <a
            className="action-reader open-reader"
            style={{ backgroundColor: colorBtn }}
            onClick={() => changeScan()}
          >
            Ler QRCode
          </a>

          <a
            className="action-reader open-reader"
            style={{ backgroundColor: colorBtn }}
            onClick={() => setListQrcode(true)}
          >
            Lista de QRCode
          </a>
        </>
      )}
    </>
  );
}
