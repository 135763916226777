import React from "react";
import "./styled.css";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function ListQrcode(props) {
  const { isOpen, toggle } = props;

  const data = [
    {
      name: "QRCode 1",
      read: true,
    },
    {
      name: "QRCode 2",
      read: false,
    },
    {
      name: "QRCode 3",
      read: false,
    },
    {
      name: "QRCode 4",
      read: true,
    },
    {
      name: "QRCode 5",
      read: true,
    },
    {
      name: "QRCode 6",
      read: false,
    },
  ];

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Lista de QRCodes</ModalHeader>
        <ModalBody>
          {data.map((e, i) => (
            <div
              className="div-option-qrcode"
              style={{ backgroundColor: e.read ? "#1dd71d" : "#d1d1d1" }}
            >
              <a>{e.name}</a>
            </div>
          ))}
        </ModalBody>
      </Modal>
    </>
  );
}
